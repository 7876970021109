import deps from "../depsloader";

export default () => {
  const elms = document.querySelectorAll(".lightgallery");
  if (!elms || elms.length == 0) {
    return;
  }

  deps.load("lightgallery").then((lg) => {
    for (let element of elms) {
      let config = {
        licenseKey: 'info@screenwork.de',
        plugins: [lg.lgThumbnail]
      };
      if (element.dataset.config) {
        config = {...config, ...JSON.parse(element.dataset.config)};
        element.removeAttribute("data-config");
      }

      lg.lightGallery(element, config); // eslint-disable-line
    }

    const exposeBtn = document.querySelector("#exGalleryBtn");
    if (exposeBtn) {
      exposeBtn.addEventListener("click", function(e) {
        e.preventDefault();
        const gallery = document.querySelector("#exGallery");
        if (gallery) {
          gallery.firstElementChild.click();
        }
      })
    }
  });
}
