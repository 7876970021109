import deps from "../depsloader";

export default () => {
  const elms = document.querySelectorAll(".iframe-resizer");
  if (!elms || elms.length == 0) {
    return;
  }

  deps.load("iframe-resizer").then((iFrameResize) => {
    for (let element of elms) {
      iFrameResize.iframeResizer({
        log: false,
        onInit: () => {
          if (element.dataset.nonce) {
            element.iFrameResizer.sendMessage({
              "opcode": "init",
              "nonce": element.dataset.nonce
            });
          }
        }}, element);
      }
  });
}
