import Vue from "vue";
import depsloader from "../depsloader";
import Listing from "../components/immo/Listing.vue";

export default () => {
  const element = document.querySelector("#vue-immo-listing");
  if (element) {
    depsloader.load("axios").then((axios) => {
      depsloader.load("vue-axios").then((VueAxios) => {
        Vue.use(VueAxios, axios);

        var status = null;
        if (element.dataset.status) {
          status = element.dataset.status;
        }

        new Vue({
          el: element,
          components: {
            Listing
          },
          render: h => h(Listing, { props: { status: status } })
        });
      });
    });
  }
};
