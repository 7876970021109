<template>
  <edit-text-field :filled="filled" :outlined="outlined" :label="label" :value="value" :suffix="unit" @input="update" v-if="type == 'currency'" format="currency"></edit-text-field>
  <edit-text-field :filled="filled" :outlined="outlined" :label="label" :value="value" :suffix="unit" @input="update" v-else-if="type == 'number'" format="decimal"></edit-text-field>
  <edit-text-field :filled="filled" :outlined="outlined" :label="label" :value="value" :suffix="unit" @input="update" v-else-if="type == 'decimal'" format="decimal"></edit-text-field>
  <edit-text-field :filled="filled" :outlined="outlined" :label="label" :value="value" :suffix="unit" @input="update" v-else-if="type == 'int'" format="int"></edit-text-field>
</template>

<script>
import EditTextField from "../utils/EditTextField";

export default {
  name: "InquiryFieldNumber",

  components: {
    EditTextField
  },

  props: {
    value: [String, Number],
    unit: String,
    label: String,
    type: String,
    filled: {
      type: Boolean,
      default: true
    },
    outlined: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    suffix() {
      return this.unit;
    }
  },

  methods: {
    update(value) {
      this.$emit("update", value);
    }
  }
};
</script>
