<template>
<v-app>
<div class="immoapp__finder" data="app">
  <div class="wp-block-group has-light-background-color has-background is-style-space-small">
    <div class="wp-block-group__inner-container">
    <v-form @submit.prevent="search">

      <v-row
        class="mt-2"
      >
        <v-col
          cols="24"
          lg="4"
          md="12">
          <v-select
            :items="marketingTypes"
            label="Käufer & Mieter"
            v-model="marketingType"
            filled
            clearable
            attach
          ></v-select>
        </v-col>

        <v-col
          cols="24"
          lg="4"
          md="12">
          <v-select
            :items="categories"
            v-model="category"
            label="Kategorie"
            :return-object="true"
            filled
            clearable
            attach
          ></v-select>
        </v-col>

        <v-col
          cols="24"
          md="8"
          lg="8">
          <v-select
            :items="locations"
            label="Standort"
            v-model="locationIds"
            filled
            clearable
            attach
          ></v-select>

        </v-col>

        <v-col
          cols="24"
          md="8"
          lg="4">
          <inquiry-field-input
            label="Preisvorstellung"
            type="number"
            :value="price"
            @update="updatePrice"
            unit="€"
            :filled="true"
            :outlined="false">
          </inquiry-field-input>
        </v-col>

        <v-col
          cols="24"
          md="8"
          lg="4">
          <button type="submit" class="btn btn-block btn-primary btn-finder">Suchen</button>
        </v-col>
      </v-row>

    </v-form>
    </div>
  </div>

  <div ref="results" v-if="results">

    <div class="wp-block-group is-style-space-big">
      <div class="wp-block-group__inner-container text-center">
          <p class="claim text-primary" v-if="results.length >=9 ">+100</p>
          <p v-if="results.length > 0 && results.length < 9 && results.length " class="claim text-primary">{{ results.length }}</p>
      </div>
    </div>

    <hr class="immoapp__finder__divider mb-0" />

    <div class="wp-block-group is-style-space-big" v-if="results.length">
      <div class="wp-block-group__inner-container ">
          <p class="h2 my-2 text-center">Ihre Interessenten</p>


        <p class="h4 text-center" v-if="results.length >=10">Es wurden viele potentielle Interessenten gefunden. <br/>
          Bitte verfeinern Sie Ihre Suche um den optimalen Interessenten zu finden.</p>

        <div class="row p-t-lg">
          <template v-for="item in resultsShort">
            <div class="col-24 col-md-12 col-lg-8" :key="item.id">
              <div class="immolisting__wrapper savedsearch savedsearch--found">
                <div class="savedsearch__content savedsearch__content--100">
                  <h3>
                    <span v-if="item.maxPrice != 0 || item.minPrice != 0">
                        <span v-if="item.minPrice != 0">
                         <span v-if="item.maxPrice == 0">{{ $t("fieldMin") }}</span> {{ $n(item.minPrice, "currency") }}
                        </span>
                        <span v-if="item.minPrice != 0 && item.maxPrice != 0">
                          &ndash;
                        </span>
                        <span v-if="item.maxPrice != 0">
                          <span v-if="item.minPrice == 0">{{ $t("fieldMax") }}</span> {{ $n(item.maxPrice, "currency") }}
                        </span>
                    </span>
                    <span v-else>
                      Keine Angabe
                    </span>
                  </h3>

                  <div class="immolisting__wrapper">
                    <ul class="savedsearch__facts">
                      <li>
                          <span class="fas fa-home"></span>

                          <title-field :category="getCategory(item.categoryId)" :marketingType="item.marketingType"></title-field>
                      </li>

                      <li>
                          <span class="fas fa-map-marker-alt"></span>
                          <span v-if="item.city">
                            <span v-if="item.zip">{{ item.zip }} </span>
                            <span v-if="item.city && item.district">{{ item.city }}–{{ item.district }}</span>
                            <span v-else-if="item.city">{{ item.city }}</span>
                          </span>
                          <span v-else>
                            Keine Angabe
                          </span>
                      </li>

                      <li>
                        <span class="fas fa-hammer"></span>
                        <span v-if="item.minConstructionYear != 0 && item.maxConstructionYear != 0">
                          Baujahr: {{ item.minConstructionYear }} – {{ item.maxConstructionYear }}
                        </span>
                        <span v-else-if="item.minConstructionYear != 0">
                          Baujahr: {{ $t("fieldMin") }} {{ item.minConstructionYear }}
                        </span>
                        <span v-else-if="item.maxConstructionYear != 0">
                          Baujahr: {{ $t("fieldMax") }} {{ item.maxConstructionYear }}
                        </span>
                        <span v-else>
                          Baujahr: -
                        </span>
                      </li>
                    </ul>

                    <ul class="immolisting__iconfields">
                      <li v-if="item.minNumberRooms">
                          <span class="value" v-if="item.minNumberRooms">
                          <span class="fas fa-bed"></span> {{ $t("fieldMin") }}&nbsp;{{ $n(item.minNumberRooms, "decimal") }}</span>
                          <span class="value" v-else-if="item.maxNumberRooms">
                          <span class="fas fa-bed"></span> {{ $t("fieldMax") }}&nbsp;{{ $n(item.maxNumberRooms, "decimal") }}</span>
                          <span class="key">Zimmer</span>
                      </li>
                      <li v-if="item.minLivingSpace">
                          <span class="value" v-if="item.minLivingSpace">
                          <span class="fas fa-vector-square"></span> {{ $t("fieldMin") }}&nbsp;{{ $n(item.minLivingSpace, "decimal") }} m²</span>
                          <span class="value" v-else-if="item.maxLivingSpace">
                          <span class="fas fa-vector-square"></span> {{ $t("fieldMax") }}&nbsp;{{ $n(item.maxLivingSpace, "decimal") }} m²</span>
                          <span class="key">Wohnfläche</span>
                      </li>
                      <li v-if="item.minPlotArea">
                          <span class="value" v-if="item.minPlotArea">
                          <span class="fas fa-street-view"></span> {{ $t("fieldMin") }}&nbsp;{{ $n(item.minPlotArea, "decimal") }} m²</span>
                          <span class="value" v-else-if="item.maxPlotArea">
                          <span class="fas fa-street-view"></span> {{ $t("fieldMax") }}&nbsp;{{ $n(item.maxPlotArea, "decimal") }} m²</span>
                          <span class="key">Grundstück</span>
                      </li>
                    </ul>
                  </div>
                </div>

                <a :href="'/kontakt/?message=' + createMailText(item)" class="btn btn-primary btn-block">Immobilie anbieten</a>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

  </div>
  <div v-else>
    <hr class="immoapp__finder__divider mb-0" />
  </div>
</div>
<div id="anbieten"></div>
</v-app>
</template>

<script>
import InquiryFieldInput from "./contact/InquiryFieldInput";
import config from "./contact/ConfigSearchInquiry";
import depsloader from "../../depsloader";
import TitleField from "./fields/TitleField";

export default {
  name: "FinderApp",

  props: [  ],

  components: {
    InquiryFieldInput,
    TitleField
  },

  data: () => ({
    marketingType: "",
    category: "",
    price: null,
    loading: false,
    results: null,
    addressModel: "",
    marketingTypes: [
      {
        text: 'Käufer',
        value: "buy"
      },
      {
        text: 'Mieter',
        value: "rent"
      }
    ],
    locations: [
      {text: "Konstanz", value: [225]},
      {text: "Ludwigshafen", value: [217]},
      {text: "Allensbach", value: [339]},
      {text: "Reichenau", value: [337]},
      {text: "Singen", value: [215]},
      {text: "Meersburg", value: [1056]},
      {text: "Petershausen West", value: [12454]},
      {text: "Petershausen Ost", value: [12455]},
      {text: "Sipplingen", value: [210]},
      {text: "Überlingen", value: [368]}
    ],
    locationIds: []
  }),

  computed: {
    categories() {
      const list = [];

      config.categoryList.forEach(c => {
        c.text = this.$t(c.text);
        if (("marketingType" in c && c.marketingType == this.marketingType) || !("marketingType" in c)) {
          list.push(c);
        } else if (!this.marketingType) {
          list.push(c);
        }
      });

      return list;
    },

    resultsShort() {
      return this.results.slice(0, 12);
    },
  },

  methods: {
    getCategory(id) {
      let category = {
        text: this.$t("Alles")
      };

      config.categoryList.forEach(c => {
        if (c.id == id) {
          category = c;
        }
      });

      return category;
    },

    updateGeo(geo) {
      this.addressModel = geo;
    },

    updatePrice(price) {
      this.price = price;
    },

    createMailText(item) {
      let message = "";
      message += "Kategorie: " + this.getCategory(item.categoryId).text;

      if (item.marketingType == "buy") {
        message += " zum Kauf";
      } else if (item.marketingType == "rent") {
        message += " zur Miete";
      }

      message += " // ";

      if (item.city) {
        message += "Stadt: " + item.city + " - " + item.zip + " // ";

        if (item.district) {
          message += " -- " + item.minConstructionYear + " // ";
        }
      }

      if (item.minPrice || item.maxPrice) {
        message += "Preis: ";
        if (item.minPrice) {
          message += this.$n(item.minPrice, "currency");
        }

        if (item.minPrice && item.maxPrice) {
          message += " - ";
        }

        if (item.maxPrice) {
          message += this.$n(item.maxPrice, "currency");
        }

        message += " // ";
      }

      if (item.minConstructionYear || item.maxConstructionYear) {
        message += "Baujahr: ";
        if (item.minConstructionYear) {
          message += item.minConstructionYear;
        }

        if (item.minConstructionYear && item.maxConstructionYear) {
          message += " - ";
        }

        if (item.maxConstructionYear) {
          message += item.maxConstructionYear;
        }

        message += " // ";
      }

      if (item.minNumberRooms || item.maxNumberRooms) {
        message += "Zimmer: ";
        if (item.minNumberRooms) {
          message += item.minNumberRooms;
        }

        if (item.minNumberRooms && item.maxNumberRooms) {
          message += " - ";
        }

        if (item.maxNumberRooms) {
          message += item.maxNumberRooms;
        }

        message += " // ";
      }

      if (item.minLivingSpace || item.maxLivingSpace) {
        message += "Wohnfläche: ";
        if (item.minLivingSpace) {
          message += item.minLivingSpace + "m² ";
        }

        if (item.minLivingSpace && item.maxLivingSpace) {
          message += " - ";
        }

        if (item.maxLivingSpace) {
          message += item.maxLivingSpace + "m² ";
        }

        message += " // ";
      }

      if (item.minPlotArea || item.maxPlotArea) {
        message += "Grundstück: ";
        if (item.minPlotArea) {
          message += item.minPlotArea + "m² ";
        }

        if (item.minPlotArea && item.maxPlotArea) {
          message += " - ";
        }

        if (item.maxPlotArea) {
          message += item.maxPlotArea + "m² ";
        }

        message += " // ";
      }

      return encodeURI(message);
    },

    search() {
      const params = {
        categoryId: this.category ? this.category["id"] : null,
        locationIds: this.locationIds,
        marketingType: this.marketingType,
        maxPrice: this.price
      };

      this.loading = true;
      this.results = [];

      depsloader.load("axios").then((axios) => {
        axios({
          method: "get",
          url: "/wp-json/rest/kaeufer-mieter-finder",
          params: params
        }).then((res) => {
          if (res.data && res.data.length > 0) {
            this.results = res.data;
            this.$refs.results.scrollIntoView()
          }

        }).catch(() => {
          this.error = true;
          this.loading = false;
        }).finally(() => {
          this.loading = false;
        });
      });
    }
  }
};
</script>
