import Vue from "vue";
import depsloader from "../depsloader";
import Archive from "../components/testimonials/Archive.vue";

export default () => {
  const element = document.querySelector("#vue-testimonial-archive");
  if (element) {
    depsloader.load("axios").then((axios) => {
      depsloader.load("vue-axios").then((VueAxios) => {
        Vue.use(VueAxios, axios);

        var ignorePosts = [];
        if (element.dataset.ignorePosts) {
          ignorePosts = JSON.parse(element.dataset.ignorePosts);
        }

        let vueArgs = {};
        vueArgs = {...vueArgs, ...{
          el: element,
          components: {
            Archive
          },

          render: h => h(Archive, { props: { ignorePosts: ignorePosts } })
        }};

        new Vue(vueArgs)
      });
    });
  }
};
